import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Homepage from './Homepage';
import './scss/App.scss';
import ServicesProgramming from "./ServicesProgramming";
import './i18n/i18n';
import { useTranslation } from 'react-i18next';

function App() {
    const { t } = useTranslation();

    return (
        <Router>
            <div className="App">
                <Routes>
                    <Route path="/" element={<Homepage />} />
                    <Route path={`${t('url-development-services')}`} element={<ServicesProgramming />} />
                </Routes>
            </div>
        </Router>
    );
}

export default App;
