import React, { Component } from 'react';

class Menu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hoveredIndex: null, // Using null to indicate no item is hovered
        };
    }

    setHover = index => {
        this.setState({ hoveredIndex: index });
    };

    resetHover = () => {
        this.setState({ hoveredIndex: null });
    };

    render() {
        const { items, stacked } = this.props;
        const { hoveredIndex } = this.state;

        return (
            <div className={hoveredIndex !== null ? "menu"  : "menu blurred\""}>
                {stacked ? (
                    <div className="text-left">
                        {items.map((item, index) => (
                            <div key={index}
                                 onMouseEnter={() => this.setHover(index)}
                                 onMouseLeave={this.resetHover}>
                                <a href={item.url} title={item.label} className={hoveredIndex === index ? "" : "blur"}>
                                    {item.label}
                                </a>
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className="text-left" style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {items.map((item, index) => (
                            <div key={index} style={{ marginRight: '10px' }}
                                 onMouseEnter={() => this.setHover(index)}
                                 onMouseLeave={this.resetHover}>
                                <a href={item.url} title={item.label} className={hoveredIndex === index ? "" : "blur"}>
                                    {item.label}
                                </a>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        );
    }
}

export default Menu;
