import React, {useState, useEffect} from 'react';
import logo from './imgs/tp_logo.svg'; // Assuming you have a logo.svg in your src folder
import './scss/Homepage.scss';
import './components/Head.js';
import Head from "./components/Head";
import './i18n/i18n';
import {Trans, useTranslation} from 'react-i18next';
import ContactForm from "./components/ContactForm";

function ServicesProgramming() {
    const {t} = useTranslation();
    const contactEmailName = "info";
    const contactEmailDomain = t('email-domain');
    const title = t('seo-services-programming-title');
    const description = t('seo-services-programming-description');
    const keywords = t('seo-keywords');

    const [emailAddress, setEmailAddress] = useState('');

    // Assemble the email address once component mounts
    useEffect(() => {
        setEmailAddress(`${contactEmailName}@${contactEmailDomain}`);
    }, []);

    return (
        <div className="container">
            <Head title={title} description={description} keywords={keywords} image={logo}/>
            <header>
                <a href="/">
                    <img src={logo} alt={t('seo-services-programming-title')}
                         title={t('seo-services-programming-title')} className="logo"/>
                </a>
            </header>
            <main>
                <section className="py-3 px-2 my-6 bg-primary text-secondary section-cut-corners">
                    <h1 className="p-0 m-0">{t('services-programming-h1')}</h1>
                </section>
                <section className="text-left">
                    <div className="row">
                        <div className="col-sm-1-1 col-md-1-2 col-lg-1-2">
                            <h2 className="m-0 p-0">{t('services-programming-main-about')}</h2>
                            <br/>
                            <p>
                                <Trans i18nKey="services-programming-main-content-1">
                                    Teikiame programavimo paslaugas šiomis programavimo kalbomis
                                    – <strong>PHP</strong>, <strong>JavaScript</strong> ir <strong>Python</strong>.
                                </Trans>
                            </p>
                            <p>
                                <Trans i18nKey="services-programming-main-content-2">
                                    Dirbame su Duomenų bazių sistemų optimizavimu, diegimu ir duomenų apsaugojimu,
                                    duomenų bazės su kuriomis
                                    dirbame: <strong>MySQL</strong>, <strong>PostgreSQL</strong>, <strong>MongoDB</strong>,
                                    <strong>Redis</strong>, <strong>MariaDB</strong>.
                                </Trans></p>
                            <p>
                                <Trans i18nKey="">
                                    Programuojame reprezentacinio pobūdžio svetaines ir sudėtingas web aplikacijas
                                    kurios optimizuoja verslų darbo veiklą ir/arba procesus. Sistemos su kuriomis
                                    dirbame: <strong>Laravel</strong>, <strong>WordPress</strong>, <strong>ReactJS</strong>, <strong>Shopify</strong>, <strong>OctoberCMS</strong>,
                                    <strong>Symfony</strong>, <strong>VueJS</strong>
                                </Trans>
                            </p>
                            <p>{t('services-programming-main-content-4')}</p>
                        </div>
                        <div className="col-sm-1-1 col-md-1-2 col-lg-1-2">

                        </div>
                    </div>
                </section>
                <section className="my-4">
                    <div className="grid">
                        <div className="col-1-of-1">
                            <h2 className="p-0 m-0 py-1 text-left">{t('contacts-title')}</h2>
                            <div className="my-4">
                                <ContactForm/>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <footer className="footer">
                <p className="footer-content">&copy; {new Date().getFullYear()} {t('footer-tallpro')}</p>
                <p className="footer-content">{t('footer-emailus')} <a
                    href={`mailto:${emailAddress}`}>{emailAddress}</a></p>
            </footer>
        </div>
);
}

export default ServicesProgramming;
